import React from 'react';
import './index.scss'
export default function LabelValue({ label, value = '-', labelColor = 'var(--text-light)', valueColor = 'var(--text-dark)', className = '', fontSize = 12, noWrap = false }) {
  return (
    <div className={`label-value-layout ${className}`} style={{ fontSize }}>
      <span style={{ color: labelColor }}>{label}：</span>
      <span title={value} className={`${noWrap ? 'no-wrap' : ''}`} style={{ color: valueColor }}>{value}</span>
    </div>
  );
}
